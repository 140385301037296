/**
 * This script contains site specific logic
 * Made at Oxys.
 */

(function ($) {

  /*************************************************
   *
   * Global events
   *
   *************************************************/

  $(document).ready(function () {
    // Should not contain breakpoint specific stuff, should be handled by pagestates
    Oxys.dispatch({
      'any'          : {
        'setResponsiveState': [],
        'slideMenuLeft'     : [],
        'goToTop'           : [],
        'clickOnEnter'      : [],
        'setFocus'          : ['#header-wrap', 'input', '[data-focus="target"]'],
        'skipToContent'     : [],
        'checkExplorer'     : [],
        'addEmptyTab'       : [],
        'addVideoWrapper'   : [],
        'clickLogin'        : [],
        'outageClickHandler': [],
        'recyclingGuide'    : [],
        'lightbox'          : []
      },
      'front'        : {
        'exampleMethod': ['args']
      },
      'page-taxonomy': {
      }
    });
  });


  $(window).resize(function () {
    Oxys.resizeTimeOut();
  });

  /**
   * Do stuff on normal and ajax page loads
   * @type {{attach: attach}}
   */
  Drupal.behaviors.Oxys = {
    attach: function (context, settings) {
      Oxys.boostrapFixes(context);
    }
  };

  /*************************************************
   *
   * Initalize, utility functions
   *
   *************************************************/

  var Oxys = {
    // Used for state handling
    pageStates: {
      enabled : [],
      states  : {
        breakpoints: {
          xxs: ['mobilePullToTop', 'mobileSearch', 'appendAroundFooter', 'pageBorder'],
          xs : ['mobileSearch', 'appendAroundFooter', 'pageBorder'],
          sm : ['mobileSearch', 'appendAroundFooter', 'pageBorder'],
          md : ['goToLogin', 'teaserHeight'],
          lg : ['goToLogin', 'teaserHeight']
        }
      },
      // See bottom of script
      handlers: {}
    }
  };


  /**
   * Cache jQuery results.
   * Use $m() instead os $() when you want to cache result for performance
   * @param query
   * @returns {*}
   */
  var $memory = [];

  function $m(query, clearCache) {
    if (query in $memory && !clearCache) {
      return $memory[query];
    } else {
      return $memory[query] = $(query);
    }
  }


  /**
   * Cache of existing elements
   * @param query
   * @returns bool
   */
  var hasElement = [];

  function $has(query, clearCache) {
    if (query in hasElement && !clearCache) {
      return hasElement[query];
    } else {
      return hasElement[query] = ($m(query, clearCache).length > 0);
    }
  }

  // add indexOf to !E < 9
  if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (what, i) {
      i = i || 0;
      var L = this.length;
      while (i < L) {
        if (this[i] === what) return i;
        ++i;
      }
      return -1;
    };
  }


  /**
   * Handle different page states for different breakpoints, moving nodes in DOM etc.
   */

  /**
   * Switch to a given display state. Checks diff against current applied.
   * @param desiredStates
   */
  Oxys.pageStates.setStates = function (desiredStates) {

    // clone currently applied states array
    var enabledStates = this.enabled.slice();

    // Compare arrays, See if there's anything to do
    if (enabledStates.sort().toString() !== desiredStates.sort().toString()) {
      // Desired does not match current
      // Assume all should be disabled
      var todo = {enable: [], disable: enabledStates};
      // Create a diff
      var arrayLength = desiredStates.length;
      for (var i = 0; i < arrayLength; i++) {
        var stateName = desiredStates[i];
        // state is desired
        var index = enabledStates.indexOf(stateName);
        if (index === -1) {
          // state is not enabled
          todo.enable.push(stateName);
        } else {
          // state is desired and enabled
          todo.disable.splice(index, 1);
        }
      }

      // Disable first
      arrayLength = todo.disable.length;
      for (i = 0; i < arrayLength; i++) {
        this.toggleState(todo.disable[i], false);
      }

      // Enable
      arrayLength = todo.enable.length;
      for (i = 0; i < arrayLength; i++) {
        this.toggleState(todo.enable[i], true);
      }

    }
  };


  /**
   * Turn a state on or off by calling its enable and disable methods respectively
   *
   * @param handlerName
   * @param state
   * @returns {boolean}
   */
  Oxys.pageStates.toggleState = function (handlerName, state) {

    if (typeof this.handlers[handlerName] !== 'undefined') {
      // Handler exists
      var handler = this.handlers[handlerName];
      var index = this.enabled.indexOf(handlerName);
      var isEnabled = (index >= 0);

      if (state === true) {
        // We are going to enable

        if (isEnabled) {
          // already enabled
          return true;
        }

        if (handler.enable()) {
          // We successfully enabled
          // TODO: sanitize class name
          // TODO: Remove jQuery dependency
          $m('body').addClass('state-' + handlerName);
          // Add handler to enabled
          this.enabled.push(handlerName);
          return true;
        } else {
          console.log('Could not apply state: ' + handlerName);
          return false;
        }

      } else {
        // We are going to disable

        if (!isEnabled) {
          // already disabled
          return true;
        }

        if (handler.disable()) {
          // We successfully disabled
          $m('body').removeClass('state-' + handlerName);
          // Remove handler from enabled
          this.enabled.splice(index, 1);
          return true;
        } else {
          console.log('Could not restore state: ' + handlerName);
          return false;
        }
      }
    } else {
      console.log('Undefined handler: ' + handlerName);
      return false;
    }
  };


  /*************************************************
   *
   * Oxys methods
   *
   *************************************************/

  /**
   * A method for initalizing methods ONLY on desired pages/templates
   */
  Oxys.dispatch = function (initializers) {
    for (var template in initializers) {
      if (template === "any" || $m('body').hasClass(template)) {
        var methods = initializers[template];
        for (var m in methods) {
          if (typeof Oxys[m] !== 'undefined') {
            // Make sure we are passing an array and that it's flat
            Oxys[m].apply(this, Array.prototype.concat.apply([], new Array(methods[m])));
          }
        }
      }
    }
  };

  /**
   * Runs on window resize, calls setResponsiveState after delay
   */
  Oxys.resizeTimeOut = function () {
    window.clearTimeout(Oxys.resizeTimer);
    Oxys.resizeTimer = window.setTimeout(Oxys.setResponsiveState, 300);
  };

  /**
   * Set page states based on breakpoints
   */
  Oxys.setResponsiveState = function () {
    var breakpoint = Oxys.getBreakpoint();
    if (typeof Oxys.pageStates.states.breakpoints[breakpoint] !== 'undefined') {
      Oxys.pageStates.setStates(Oxys.pageStates.states.breakpoints[breakpoint]);
    }
  };

  /**
   * Find the active breakpoint from content of pseudo-element set by mediaquery in css
   *
   * @returns {string}
   */
  Oxys.getBreakpoint = function () {
    var win = window,
      doc = win.document,
      el,
      pseudo;
    if (win.getComputedStyle && doc.querySelector) {
      el = doc.querySelector('body');
      pseudo = win.getComputedStyle(el, ':after').getPropertyValue('content');
      if (pseudo) {
        // Firefox bug replace
        return pseudo.replace(/[^\w]/g, '');
      }
    } else {
      // Default for old browsers
      return 'md';
    }
  };


  /**
   * Return true if current breakpoint is in passed array
   *
   * @param breakpoints
   * @returns {boolean}
   */
  Oxys.isBp = function (breakpoints) {
    var index = $.inArray(Oxys.getBreakpoint(), breakpoints);
    return (index >= 0);
  };

  /*************************************************
   *
   * Oxys page state handlers
   *
   *************************************************/

  // function to match the heights for sidebar and content
  Oxys.equalHeight = function (property, isPropertyRemoved) {
    $('.outer-wrapper').find('.site-wrap').matchHeight({
      property: property,
      remove: isPropertyRemoved
    });
  };

  Oxys.pageStates.handlers.setHeight = {
    // corrects the height of sidebar + stick the footer to bottom
/*    enable : function () {
      console.log('set height');
      var $footerHeight = $m('.region-footer').height(),
        padding = 75,
        prev = $m('.region-footer').prev(),
        footerPadding = 75;

      Oxys.equalHeight('min-height', false);
      prev.css('padding-bottom', $footerHeight + footerPadding);
      $m('.region-footer').addClass('region-footer--fixed');
      return true;
    },
    disable: function () {
      var prev = $m('.region-footer').prev();
      prev.css('padding-bottom', '');
      $m('.region-footer').removeClass('region-footer--fixed');
      Oxys.equalHeight('min-height', true);
      return true;
    }*/
  };

  Oxys.pageStates.handlers.mobilePullToTop = {
    enable : function () {
      $('.mobile-pull-to-top').each(function () {
        var elem = $(this),
          target = elem.closest('.region'),
          id = elem.data('moved-id') || Math.floor(Math.random() * 9999999);
        elem.data('moved-id', id);
        elem.before('<div id="placeholder-' + id + '"></div>');
        target.prepend(elem);
      });
      return true;
    },
    disable: function () {
      $('.mobile-pull-to-top').each(function () {
        var elem = $(this),
          id = elem.data('moved-id'),
          target = $('#placeholder-' + id);
        target.replaceWith(elem);
      });
      return true;
    }
  };

  Oxys.pageStates.handlers.goToLogin = {
    enable : function () {
      $('[data-oxys="goToLogin"]').on('click', function () {
        window.scroll(0, 0);
      });
      return true;
    },
    disable: function () {
      return true;
    }
  };

  Oxys.pageStates.handlers.teaserHeight = {
    enable : function () {
      $m('.teaser-row .panel-pane').matchHeight({
        property: 'height'
      });
      return true;
    },
    disable: function () {
      $m('.teaser-row .panel-pane').matchHeight({
        remove: true
      });
      return true;
    }
  };

  Oxys.pageStates.handlers.mobileSearch = {
    enable: function () {
      $('#search-block').before('<div id="placeholder-search-block"></div>');
      $('#search-block').insertBefore('#main-content')
          .addClass('moved');
      return true;
    },
    disable: function () {
      var searchBlock = $('#search-block');
      $('#placeholder-search-block').replaceWith(searchBlock);
      searchBlock.removeClass('moved');
      return true;
    }
  };

  Oxys.pageStates.handlers.appendAroundFooter = {
    enable: function () {
      var $block = $('[data-appendaround="md"]'),
          $containerXS = $('[data-appendaround="xs"]');
      $containerXS.replaceWith($block);
      return true;
    },
    disable: function () {
      var $block = $('[data-appendaround="md"]'),
          $containerMD = $('[data-appendaround="md_container"]');
      $containerMD.css('display', 'block');
      $containerMD.replaceWith($block);
      return true;
    }
  };

  Oxys.pageStates.handlers.pageBorder = {
    enable: function () {
      var $border = $('<div class="pageborder"></div>');
      $('h1').prepend($border);
      return true;
    },
    disable: function () {
      var $border = $('.pageborder');
      $border.remove();
      return true;
    }
  };

  Oxys.pageStates.handlers.addMyPagesLink = {
/*      enable: function () {
        var menuLink = $('<li class="leaf my-pages-login"> <a title="Mina sidor" data-toggle="collapse" data-parent="#header-wrap" data-focus="target" href="#mina-sidor">Mina sidor</a></li>');
        menuLink.prependTo($('#menu-municipalities-secondary').find('ul.menu.nav').first());
        return true;
      },
      disable: function() {
        var menuLink = $('.my-pages-login');
        menuLink.remove();
        return true;
      }*/
      };

  Oxys.menuHandler = function () {
    var activeMenuItem = $('.menu-name-main-menu li.first');
    $('.handler').click(function () {
      console.log('click');
      $('.menu-name-main-menu ul.menu').toggleClass('open');
    });
  };

  Oxys.slideMenuLeft = function () {
    var slideSpeed = 150,
      $btn = $m('[data-toggle="navTrigger"]'),
      $nav = $m('[data-toggle="navSidebar"]'),
      $body = $m('body');

    $body.append('<div class="overlay" data-cover>');

    var $overlay = $('[data-cover]');

    var animateNav = function () {
      $body.toggleClass('noscroll');
      $overlay.toggleClass('cover');
      $nav.animate(
        {left: parseInt($nav.css('left'), 10) === 0 ? -$nav.outerWidth() : 0},
        {
          duration: slideSpeed
        });
    };

    $btn.on('click', function () {
      animateNav();
    });
    $overlay.on('click', function () {
      animateNav();
    });
  };

  Oxys.goToTop = function () {
    var $trigger = $m('[data-oxys="gotop"]'),
        scrollSpeed = 800;

    $trigger.click(function () {
      $m('html, body').animate({scrollTop: 0}, scrollSpeed);
      return false;
    });
  };

  Oxys.recyclingGuide = function () {

    $('body').on('click', 'td.views-field-title a', function(e) {
      e.stopPropagation();
    });

    $('body').on('click', 'td.views-field-title', function() {
      $(this).toggleClass('open');
    });

  };

  Oxys.setFocus = function (parent, element, trigger) {
    var $el = $(parent).find(element);
    $(trigger).on('click touchstart', function () {
      setTimeout(function () {
        $el.focus();
      }, 200);
    });
  };

  Oxys.clickOnEnter = function () {
    var $trigger = $('[data-click="enter"]');
    $trigger.on('keydown', function (e) {
      if (e.keyCode === 13) {
        this.click();
      }
    });
  };

  Oxys.boostrapFixes = function (context) {
    // workaround to set correct class on load
    var $accordion = $(context).find('a.accordion-toggle');
    $accordion.each(function () {
      $(this).addClass('collapsed');
    });

    // match height when bootstrap event is completed
    $('body').on('shown.bs.collapse', function(e) {
      Oxys.equalHeight('min-height', false);
    });

    // match height when bootstrap event is completed
    $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function () {
      Oxys.equalHeight('min-height', false);
    });

    // match height when a form is loaded via select
    $('#edit-nid').on('change', function(e) {
      setTimeout(function() {
        Oxys.equalHeight('min-height', false);
      }, 300);
    });

  };

  Oxys.skipToContent = function () {
    /**
     * https://www.nczonline.net/blog/2013/01/15/fixing-skip-to-content-links/
     */
    window.addEventListener("hashchange", function (event) {
      var element = document.getElementById(location.hash.substring(1));
      if (element) {
        if (!/^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
          element.tabIndex = -1;
        }
        element.focus();
      }
    }, false);
  };

  Oxys.checkExplorer = function () {
    function getInternetExplorerVersion() {
      // Returns the version of Internet Explorer or a -1
      // (indicating the use of another browser).
      var rv = -1; // Return value assumes failure.
      if (navigator.appName == 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
          rv = parseFloat(RegExp.$1);
      }
      return rv;
    }

    function checkVersion() {
      var ver = getInternetExplorerVersion(),
          $html = $('html');
      if (ver > -1) {
        if (ver >= 8.0) {
          $html.addClass('ie');
        }
      }
    }
    checkVersion();
  };

  Oxys.addEmptyTab = function () {
    //If the content tab for a status is empty, create and insert a custom one with a "Nothing to report" text.
    var tabs = $('.outages-tab li a').each(function() {
      //Get the tab ID from the corresponding link href.
      var linkTo = $(this).attr('href'),
        cleanLinkTo = linkTo.replace('#', ''),
      //Check if there is a tab with the ID specified in the link href.
        has_tab_pane = $(linkTo).length;
      // If there is no corresponding content tab, create and append one.
        if(!has_tab_pane) {
          $(this).parentsUntil('.view-outages').find('.tab-content').append('<div class="tab-pane" id="'+cleanLinkTo+'"><div class="view-empty">'+Drupal.t('Nothing to report.')+'</div></div>');
        }
    });

    // Set visibility of correct tab content.
    $('ul.outages-tab li').each(function() {
      if ($(this).hasClass('active')) {
        var linkTo = $(this).find('a').attr('href');
        $(linkTo).addClass('active');
      }
    });
  };

  Oxys.addVideoWrapper = function () {
    var contentTeaser = $('.pane-bundle-teaser-pane');
      contentTeaser.each(function() {
        if( $(this).find('.field-name-field-image video').length > 0 ) {
          $('.field-name-field-image').addClass('has-video');
        }
      })
  };

  /**
   * Close loginbox when hitting Login
   */
  Oxys.clickLogin = function () {
    $('input[data-oxys=goToLogin]').click(function () {
      setTimeout(function () {
        $('.region-header').find('.panel-pane').removeClass('in');
        $('.region-header').find('.panel-pane').addClass('collapsing');
      }, 1500);
    });
  };

  Oxys.outageClickHandler = function () {
    $('.outage--row').each(function() {
      var linkTo = $(this).attr('data-attr');
      $(this).click(function() {
        window.location = linkTo;
      })
    })
  };

  Oxys.lightbox = function() {
    $('.lightbox-enabled').click(function() {

      var isMobile = Oxys.isBp(['xs', 'xxs', 'sm']);
      if(isMobile == true) {

        var src = $(this).find('img').attr('src');
        window.open(src);
      } else {
        $(this).toggleClass('open');
      }

    });

}

})(jQuery);
